import { createRouter, createWebHistory } from "vue-router";

const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_BASE_URL),
  routes: [
    {
      path: "/auth/",
      name: "auth",
      component: () => import("../layouts/auth.vue"),
      redirect: { name: "login" },
      meta: { requiresAuth: false },
      children: [
        {
          path: "login",
          name: "login",
          component: () =>
            import("../views/auth/Login.vue"),
        },
      ],
    },
    {
      path: "/",
      component: () => import("../layouts/default.vue"),
      redirect: { name: "tickets" },
      meta: { requiresAuth: true },
      children: [
        {
          path: "order",
          name: "orders",
          component: () => import("../views/Orders.vue"),
          meta: { requiresAuth: true, title: "سفارشات" },
        },
        {
          path: "ticket",
          children: [
            {
              path: "",
              name: "tickets",
              component: () =>
                import("../views/Tickets.vue"),
              meta: {
                requiresAuth: true,
                title: "پشتیبانی و تیکت",
              },
            },
            {
              path: ":id",
              name: "ticket-details",
              component: () =>
                import("../views/Ticket/[id].vue"),
              meta: {
                requiresAuth: true,
                title: "پشتیبانی و تیکت",
              },
            },
          ],
        },
      ],
    },
    {
      path: "/:pathMatch(.*)*",
      redirect: "/ticket",
    },
  ],
});

router.beforeEach((to, from, next) => {
  // Get token from localStorage and check if user is logged in
  const token = localStorage.getItem("user_id_token");
  const isLoggedIn = !!token;
  
  // Set document title if available in route meta
  if (to.meta.title) {
    document.title = `${to.meta.title} | License Market`;
  }

  if (to.meta.requiresAuth && !isLoggedIn) {
    // Redirect unauthenticated users to login page with redirect_url
    const redirectUrl = to.fullPath;
    next({
      path: '/auth/login',
      query: { redirect_url: redirectUrl }
    });
  } else if (isLoggedIn && to.path.startsWith('/auth/')) {
    // If user is logged in and trying to access auth pages
    // Redirect to the redirect_url query param or default to tickets page
    const redirectPath = to.query.redirect_url 
      ? String(to.query.redirect_url) 
      : '/ticket';
    next(redirectPath);
  } else {
    // Allow access for all other cases
    next();
  }
});

export default router;
